import React from 'react';
import Layout from '../components/layouts/Layout';
import ResetPasswordComponent from '../components/ResetPassword';

const ResetPassword: React.FC = () => {
    return (
        <Layout>
            <ResetPasswordComponent />
        </Layout>
    );
};

export default ResetPassword;
