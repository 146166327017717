import { Alert } from 'antd';
import { Link } from 'gatsby';
import Icon from '@mdi/react';
import Spinner from './Spinner';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import CustomInput from './CustomInput';
import { gradient, grey } from '../util/theme';
import { useForm } from "react-hook-form";
import { Button } from '@material-ui/core';
import { mdiCheckCircleOutline } from '@mdi/js';
import axios, { handleError } from '../util/axios';
import { modularScale } from '../util/modularScale';
import { getUrlParams } from '../util/url';

const Container = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 4px;
	
	@media (min-width: 450px) {
	    padding: 4rem;
	}
	
	@media (min-width: 900px) {
	    padding: 4rem;
	}
`;

const InputError = styled.span`
	color: red;
    font-size: ${modularScale(-1)};
`;

const Input = styled(CustomInput)`
	margin-bottom: 1rem;
`;

const Title = styled.h2`
	font-size: ${modularScale(1)};
	font-weight: bold;
	margin: 1rem 0 1rem;
	line-height: 1;
	
	@media (min-width: 900px) {
        margin: 0 0 1rem;
	}
`;

const Info = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
    color: #fff !important;
	background-color: #000;
	height: 42px;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
	    background-color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	
	svg {
	    width: 18px;
	    margin-left: 0.5rem;
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 1rem;
`;

const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
`;

const StyledLink = styled(Link)`
	margin-bottom: 0.5rem;
`;

const ResetPasswordForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [requestError, setRequestError] = useState('');
    const [token, setToken] = useState('');
    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        setToken(getUrlParams(document.location.search).token);
    }, []);

    const onSubmit = async data => {
        if (!token) {
            setRequestError('The password reset token is invalid or has expired. ' +
                'Please restart the password reset process.');
            return;
        }

        setIsLoading(true);
        setIsDisabled(true);

        try {
            await axios.post('/password/reset', { token, password: data.password });
            setIsSuccess(true);
        } catch (err) {
            handleError(err, () => {
                setIsDisabled(false);

                if (err.response.status === 400) {
                    setRequestError('The password you have provided is invalid. ' +
                        'Passwords must have at least 8 characters consisting of letters and numbers.');
                    return;
                }

                if (err.response.status === 401) {
                    setRequestError('The password reset token is invalid or has expired. ' +
                        'Please restart the password reset process.');
                    return;
                }

                setRequestError('An error occurred while resetting password. Please try again later.');
            })
        }

        setIsLoading(false);
    }

    const getButtonText = () => {
        if (isSuccess) {
            return <ButtonText>Password updated <Icon path={mdiCheckCircleOutline} /></ButtonText>
        }

        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Update Password';
    }

    return (
        <Container>
            { requestError && <StyledAlert message={requestError} type="error"/> }

            <Title>Reset your password</Title>

            <Info>Please provide a new password with at least 8 characters consisting of letters and numbers.</Info>

            <form onSubmit={handleSubmit(onSubmit)}>
                {errors.password && <InputError>This field is required.</InputError>}
                <Input name="password" label="Password" type="password" size="small" inputRef={register({ required: true })}/>
                <Input name="confirm_password" label="Confirm Password" type="password" size="small" inputRef={register({ required: true })}/>

                <StyledButton variant="contained" fullWidth type="submit" size="large" disableElevation disabled={isDisabled}>
                    {getButtonText()}
                </StyledButton>
            </form>

            {isSuccess && (
                <LinkWrapper>
                    <StyledLink to={'/login'}>Sign in</StyledLink>
                    <StyledLink to={'/checkout'}>Return to checkout</StyledLink>
                </LinkWrapper>
            )}
        </Container>
    );
};

export default ResetPasswordForm;
