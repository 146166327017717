export const getUrlParams = (search): any => {
    let urlParams = {};

    let match;
    let pl = /\+/g; // Regex for replacing addition symbol with a space
    let reg = /([^&=]+)=?([^&]*)/g;
    let decode = s => decodeURIComponent(s.replace(pl, " "));
    let query = search.substring(1);

    urlParams = {};
    while (match = reg.exec(query)) {
        urlParams[decode(match[1])] = decode(match[2]);
    }

    return urlParams;
}
